import { defineComponent } from 'vue';
import BaseIcon from './BaseIcon.vue';
export default defineComponent({
    name: 'SocialShareButton',
    components: { BaseIcon },
    props: { platform: { type: String, required: true } },
    setup(props) {
        // make a social share link for every platform
        const title = document.querySelector('title')?.textContent || '';
        const description = document.querySelector("meta[property='og:description']")?.content || '';
        const urlToShare = `${location.origin}`;
        const twitterUrl = `https://twitter.com/intent/tweet?text=${title}&url=${urlToShare}`;
        const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${urlToShare}&title=${title}&summary=${description}&source=${urlToShare}`;
        const xingUrl = `https://www.xing.com/spi/shares/new?url=${urlToShare}`;
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`;
        const urlToReturn = {
            twitter: twitterUrl,
            linkedIn: linkedInUrl,
            xing: xingUrl,
            facebook: facebookUrl,
        };
        return { socialShareLink: urlToReturn[props.platform] };
    },
});
